





















import { Component, Vue, Prop } from "vue-property-decorator";
import Card from "@/views/optional/components/Card.vue"
import EmptyBox from "@/components/EmptyBox.vue";
import { List } from "vant";
import SearchApi from "@/api/search.api";
@Component({
    components:{
       Card,
       EmptyBox,
       List
    }
})
export default class OptionalContent extends Vue {
    @Prop() index!:number
    checkor = true;
    type = 1
    character = -1
    is_mine = 0
    allList:any = []
    indexnumber = 0
    async check() {
        this.checkor = !this.checkor
        let flag
        if(this.checkor){
            flag = 1
        }else{
            flag = 0
        }
         this.allList = await SearchApi.getVoucherPrice(1,this.index-1,flag);
    }
    async created() {
        this.indexnumber = this.index
        let character = this.index - 1
        this.allList = await SearchApi.getVoucherPrice(1,character,1);
    }
    
}
