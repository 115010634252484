









import { getCharacter } from "@/utils/common.util";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
    components:{
        
    }
})
export default class CardImage extends Vue {
    @Prop() items!:any
    created() { }
    get currentCharacter() {
    const text = getCharacter(this.items.character, "", true);
    const icon =
      this.items.character < 5
        ? ""
        : `__character_peak_${this.items.character - 4}_icon`;
    return { text, icon };
  }
  get possibleStyle() {
    if (!this.items) return {};
    return {
      background: `var(--card-color-${this.items.character})`,
    };
  }
}
