import axios, { AxiosError } from "axios";
import { IAddress } from "@/api/mine.api";
import { CharacterDetail } from "@/api/voucher.api";
import { Toast } from "vant";

export default class SearchApi {
  static getPlayerList(id: string|(string|null)[]): never[]|PromiseLike<never[]> {
    throw new Error("Method not implemented.");
  }
  static async getSearchList(page = 1, page_size = 10, keyword = "") {
    return axios.get("/store/product/search", {
      params: {
        page,
        page_size,
        keyword: "",
      },
    });
  }
  static async test({ page = 1, page_size = 10, keyword = "" }) {
    return axios.get("cap-store/search", {
      params: {
        keyword,
        page,
        page_size,
      },
    });
  }
  static async getVoucherPrice(type = 1, character = -1, is_mine = 0) {
    return axios.get("apiv2/v3/voucher/synthetic/prize", {
      params: {
        type,
        character,
        is_mine
      },
    });
  }
  static async getPrizeDetail(id: any = '') {
    return axios.get(`apiv2/v3/voucher/synthetic/prize-one?type=1&id=${id}`);
  }
  static async getSupplyHave(character: any = 1) {
    return axios.get(`apiv2/v2/blindbox/mine/supply?character=${character}`);
  }

  static async putCombinePrize(choose: any, voucher_ids: any, entity_ids: any): Promise<{ id: number; token: string }> {
    return axios.post(`apiv2/v3/voucher/synthetic/choose`, {
      choose: Number(choose),
      voucher_ids,
      entity_ids
    })
    .then((ret: any) => ret)
    .catch((err: AxiosError) => {
      Toast(err.response?.data.msg || '合成失败')
      throw err;
    });
  }
  static async verifyToken(token: any) {
    return axios.post(`/apiv2/v2/voucher/offer/status`, { token }).then(ret => true).catch((err: AxiosError) => {
      Toast(err.response?.data.msg || '合成失败')
      return false;
    });
  }
}
