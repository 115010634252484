










import { Component, Vue } from "vue-property-decorator";
import { Tabs, Tab } from "vant"
import OptionalContent from "@/views/optional/components/OptionContent.vue"
@Component({
    components:{
        Tabs,
        Tab,
        OptionalContent
    }
})
export default class Optional extends Vue {
    ItemName = ['全部','优秀','精良','史诗','传奇','神话']
    
}
