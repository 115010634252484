


















import { Component, Vue, Prop } from "vue-property-decorator";
import CardImage from "@/views/optional/components/CardImage.vue";
import { getCharacter } from "@/utils/common.util";
@Component({
  components: {
    CardImage,
  },
})
export default class Card extends Vue {
  @Prop() items!: any;
  created() {}
  get currentCharacter() {
    const text = getCharacter(this.items.character, "", true);
    const icon =
      this.items.character < 5
        ? ""
        : `__character_peak_${this.items.character - 4}_icon`;
    return { text, icon };
  }
  get possibleStyle() {
    if (!this.items) return {};
    return {
      background: `var(--card-color-${this.items.character})`,
    };
  }
  get numberStyle() {
    if (!this.items) return {};
    return {
      color: `var(--card-color-${this.items.character})`,
    };
  }
  todetail() {
    this.$router.push({path:'/prize-detail',query:{
      id:this.items.id
    }})
  }
}
